import { extendTheme, theme as baseTheme, ThemeConfig } from '@chakra-ui/react'

import styles from './styles'
import borders from './foundations/borders'
import components from './components'

const config: ThemeConfig = {}

const breakpoints = {
  sm: '640px',
  lg: '1024px',
  xl: '1366px',
}

const colors = {
  ...baseTheme.colors,
  white: {
    '100': '#FFFFFF',
    '200': '#F9FAFE',
    '300': '#FFFFEA',
    '400': '#878791',
    '500': '#E1E0E4',
    '600': 'rgba(25, 34, 31, 1)',
    '80': 'rgba(255, 255, 255, 0.8)',
    '70': 'rgba(255, 255, 255, 0.7)',
    '60': 'rgba(255, 255, 255, 0.6)',
    '50': 'rgba(255, 255, 255, 0.5)',
    '40': 'rgba(255, 255, 255, 0.4)',
    '30': 'rgba(255, 255, 255, 0.3)',
    '10': 'rgba(255, 255, 255, 0.1)',
  },
  black: {
    '100': '#000000',
    '200': '#040D0A',
    '300': '#2C2C3D',
    '400': '#1B1B2E',
    '500': 'rgba(14, 24, 21, 1)',
  },
  gray: {
    '100': 'rgba(21, 31, 27, 1)',
    '200': 'rgba(30, 40, 36, 1)',
  },
  green: {
    '100': '#36FFC0',
    '200': '#071612',
    '50': 'rgba(87, 3, 255, 0.5)',
  },
  blue: {
    '100': '#5703FF',
    '200': 'rgba(87, 3, 255, 0.40)',
  },
  pink: {
    '100': '#FF4AE7',
  },
  red: {
    '100': '#E10200',
    '200': '#FCDCDC',
    '300': '#C70300',
  },
  yellow: {
    '100': '#EFFF36',
  },
  purple: {
    '40': 'rgba(87, 3, 255, 0.4)',
  },
  gold: {
    '100': '#946700',
  },
  silver: { '100': '#626262' },
  bronze: { '100': '#6A1300' },
  gradient: 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #071612',
  gradientGreen:
    'linear-gradient(0deg, rgba(54, 255, 192, 0.14), rgba(54, 255, 192, 0.14)), #071612',
  gradientGreen2:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), #071612',
  gradientGreen3:
    'linear-gradient(180deg, rgba(54, 255, 192, 0.47) 0%, rgba(146, 108, 255, 0.28) 100%)',
  gradientGreen4:
    'linear-gradient(0deg, rgba(54, 255, 192, 0.34) 0%, rgba(54, 255, 192, 0.34) 100%), #071612',
  gradientGreen5:
    'linear-gradient(0deg, rgba(54, 255, 192, 0.08) 0%, rgba(54, 255, 192, 0.08) 100%), #071612',
  gradientGold: 'linear-gradient(150deg, #D2861B 0%, #EEAB00 46.59%, #EABB43 100%)',
  gradientSilver: 'linear-gradient(150deg, #FFF 0%, #696969 46.59%, #CBCBCB 100%)',
  gradientBronze: 'linear-gradient(150deg, #F1C3A9 13.54%, #D67641 66.15%, #E88428 100%)',
  gradientBorder: 'linear(to-t,#F7FFEA, #4688DE, #5703FF, #FFFFFF, #36FFC0)',
  gradientPurple:
    'linear-gradient(327deg, rgba(255, 74, 231, 0.30) 0%, rgba(87, 3, 255, 0.30) 100%)',
}
const textStyles = {
  '12': {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 1,
  },
  '14': {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 1,
  },
  '16': {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 1,
  },
  '18': {
    fontSize: 18,
    fontWeight: '500',
    lineHeight: 1,
  },
  '20': {
    fontSize: 20,
    fontWeight: '500',
    lineHeight: 1,
  },
  '24': {
    fontSize: 24,
    fontWeight: '500',
    lineHeight: 1,
  },
  '30': {
    fontSize: 30,
    fontWeight: '500',
    lineHeight: 1,
  },
  '48': {
    fontSize: 48,
    fontWeight: '500',
    lineHeight: 1,
  },
  '64': {
    fontSize: 64,
    fontWeight: '500',
    lineHeight: 1,
  },
}

const layerStyles = {}

// https://chakra-ui.com/docs/theming/theme
const theme = extendTheme({
  config,
  colors,
  fonts: {
    body: '"Fixture", "SF Pro Display", "PingFang SC"',
  },
  sizes: {
    xl: '1080px',
  },
  fontSizes: {
    '12': '12px',
    '14': '14px',
    '16': '16px',
    '18': '18px',
    '24': '24px',
  },
  styles,
  borders,
  components,
  breakpoints,
  layerStyles,
  textStyles,
})

export default theme
