import { ChakraProvider, useToast, Image, Text, Flex } from '@chakra-ui/react'
import pv from '@/utils/px2vw'
import Head from 'next/head'
import type { AppProps } from 'next/app'
import { ToastContainer } from 'react-toastify'
import theme from '@/theme'
import './baseCss.css'
import '@/styles/global.scss'
import 'react-toastify/dist/ReactToastify.css'
import Layout from '@/components/Layout'
import { ParticleConnect, metaMask, walletconnect } from '@particle-network/connect'
import { PolygonMumbai } from '@particle-network/common'
import { useEffect, useState } from 'react'
import {
  deleteStore,
  getSessionStorage,
  getStore,
  removeSessionStorage,
  setSessionStorage,
  setStore,
} from '@/utils/storage'
import globalStore from '@/stores/global'
import Web3 from 'web3'
import { ParticleProvider } from '@particle-network/provider'
import { ethers } from 'ethers'
import useSWR from 'swr'
import { getTokenPrice } from '@/apis/index'
import { login } from '@/apis/user'
import { useRouter } from 'next/router'
import sdk from 'sendingnetwork-js-sdk'
import { DefaultChain, connectWallet, sendingBaseUrl, switchOrAddChain } from '@/contracts/common'
import BaseToast from '@/components/BaseToast'
import errorIcon from '@/assets/imgs/errorIcon.png'
import BaseButton from '@/components/BaseButton'

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const toast = useToast()
  const { errorChain } = globalStore()
  const [address, setAddress] = useState('')
  const { isLogin } = globalStore()
  const { data: getTokenPriceData } = useSWR(
    getTokenPrice.key,
    () => getTokenPrice.fetcher('WETH'),
    {
      revalidateOnFocus: false,
    }
  )

  const { data: getNativeCoinPriceData } = useSWR(
    [getTokenPrice.key],
    () => getTokenPrice.fetcher('MATIC'),
    {
      revalidateOnFocus: false,
    }
  )

  const { data: loginData } = useSWR(
    [login.key, address],
    () => (address ? login.fetcher(address) : null),
    {
      revalidateOnFocus: false,
    }
  )

  useEffect(() => {
    if (getTokenPriceData) {
      globalStore.setState({
        tokenPrice: String(getTokenPriceData?.prices?.USD),
      })
    }
  }, [getTokenPriceData])

  useEffect(() => {
    if (getNativeCoinPriceData) {
      globalStore.setState({
        nativeCoinPrice: String(getNativeCoinPriceData?.prices?.USD),
      })
    }
  }, [getNativeCoinPriceData])

  useEffect(() => {
    isConnect()
    initConnectKit()
    initSendingNetWork()
    metaMaskListing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getSessionStorage('walletAddress')) {
      globalStore.setState({
        isLogin: true,
        userAddress: getSessionStorage('walletAddress') as string,
      })
    }
    if (getSessionStorage('userInfo')) {
      globalStore.setState({
        userInfo: JSON.parse(getSessionStorage('userInfo') as any),
      })
    } else {
      globalStore.setState({
        isLogin: false,
        userInfo: null,
      })
    }
    if (getSessionStorage('client')) {
      globalStore.setState({
        client: getSessionStorage('client'),
      })
    }

    if (getStore('loginType') === 'particle') {
      connectWallet('particle', {
        preferredAuthType: 'phone',
        supportAuthTypes: 'email,facebook,google,apple,twitter',
      })
    }

    // const signWithDevKey = async ({ message }: any) => {
    //   if (!message) return
    //   // Make a request to your backend API to sign the message and retrieve the signature.
    //   // Note: This example demonstrates the concept; implement this API in your backend.
    //   const response = await fetch(
    //     'https://95c5210b13b1458cc481d6073ea954279ed5d0e1194a73ab0efa3f02c39df4a3/_api/appservice/sign',
    //     {
    //       method: 'POST',
    //       body: JSON.stringify({ message }),
    //     }
    //   )
    //   const { signature } = await response.json()
    //   console.log(signature, 'signature')
    //   return signature
    // }

    // window.signWithDevKey = signWithDevKey
  }, [])

  useEffect(() => {
    if (loginData) {
      setSessionStorage('userInfo', JSON.stringify(loginData))
      globalStore.setState({
        isLogin: true,
        userInfo: loginData,
      })
      setAddress('')
      if (window.loginType != 'wallet') {
        if (!loginData?.email) {
          router.push('/verifyEmail')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData])

  useEffect(() => {
    if (!errorChain) return
    toast({
      position: 'bottom',
      duration: null,
      render: () => (
        <BaseToast
          icon={<Image alt="error" src={errorIcon} w={pv(21)} h={pv(18)} mr={pv(8)} />}
          textRender={
            <Flex flexDir="column">
              <Text color="red.300" textStyle="16" fontWeight="600" lineHeight={pv(20)} mb={pv(17)}>
                Your are currently using a different chain with our network, please change to [
                {DefaultChain.name}] to continue. times
              </Text>
              <BaseButton
                border="none"
                bgColor="red.300"
                borderRadius={pv(100)}
                onClick={async () => {
                  const res = await switchOrAddChain()
                  if (res) toast.closeAll()
                }}
              >
                Select another Chain
              </BaseButton>
            </Flex>
          }
          bgColor="red.200"
          closeClick={() => toast.closeAll()}
        />
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorChain])

  const isConnect = async () => {
    if (window.ethereum && getStore('loginType') !== 'particle') {
      const ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any')
      const accounts = await ethersProvider.listAccounts()
      // 检查metamask钱包是否为链接状态
      if (accounts?.[0] && !isLogin) {
        window.loginType = 'pc wallet'
        setStore('loginType', 'pc')
        globalStore.setState({
          provider: new ethers.providers.Web3Provider(window.ethereum, 'any'),
          userAddress: accounts[0],
        })
        window.provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
        setSessionStorage('walletAddress', accounts[0])
        setAddress(accounts[0])
        setSessionStorage('walletAddress', accounts[0])
      }
    }
  }

  const initConnectKit = async () => {
    const connectKit = new ParticleConnect({
      projectId: '8da518ed-8b20-4618-b1e0-6641efef7f50',
      clientKey: 'csLWnj7bG3zAKVyAiADeJDwNGN1a1h30wJllY5Fn',
      appId: 'c1465ebe-c760-4e86-9fd8-6101ae5dbc8d',
      particleWalletEntry: {
        displayWalletEntry: false,
        uiMode: 'dark',
      },
      chains: [PolygonMumbai],
      wallets: [
        metaMask({ projectId: '2eaa5b9024eed4eb4c847de17b5fc70e' }),
        walletconnect({ projectId: '2eaa5b9024eed4eb4c847de17b5fc70e' }),
      ],
    })
    connectKit.on('disconnect', () => {
      console.log('disconnect!!!!!!')
      globalStore.setState({
        isLogin: false,
        userAddress: '',
      })
      deleteStore('sdn_user_address')
      deleteStore('sdn_user_id')
      deleteStore('sdn_access_token')
      deleteStore('loginType')
      removeSessionStorage('walletAddress')
    })
    connectKit.on('accountsChanged', (accounts) => {
      console.log('账号变化：', accounts)
      if (accounts && accounts[0]) {
        window.loginType = 'wallet'
        globalStore.setState({
          // isLogin: true,
          provider: new ethers.providers.Web3Provider(window.ethereum, 'any'),
          userAddress: accounts[0],
        })
        window.provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
        setSessionStorage('walletAddress', accounts[0])
        setAddress(accounts[0])
      }
    })
    connectKit.on('connect', async (provider) => {
      console.log('particle 登陆', provider)
      window.provider = provider
      window.web3 = new Web3(provider as any)
      // @ts-ignore
      if (provider?.isParticleNetwork) {
        window.loginType = 'particle'
        setStore('loginType', 'particle')
        const particleProvider = new ParticleProvider(window.particle.auth)
        const ethersProvider = new ethers.providers.Web3Provider(particleProvider, 'any')
        const accounts = await ethersProvider.listAccounts()
        globalStore.setState({
          isLogin: true,
          provider: particleProvider,
          userAddress: accounts[0],
        })
        setAddress(accounts[0])
        setStore('particleProvider', ethersProvider)
        window.particleProvider = ethersProvider
        window.web3 = new Web3(particleProvider as any)
      }
    })
    window.connectKit = connectKit
  }

  const metaMaskListing = () => {
    if (window.ethereum) {
      window?.ethereum?.on('accountsChanged', (accounts: any) => {
        console.log('meta mask change account：', accounts)
        if (accounts && accounts[0]) {
          window.loginType = 'pc wallet'
          setStore('loginType', 'pc')
          globalStore.setState({
            // isLogin: true,
            provider: new ethers.providers.Web3Provider(window.ethereum, 'any'),
            userAddress: accounts[0],
          })
          window.provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
          setSessionStorage('walletAddress', accounts[0])
          setAddress(accounts[0])
        } else {
          globalStore.setState({
            isLogin: false,
            userAddress: '',
          })
          deleteStore('sdn_user_address')
          deleteStore('sdn_user_id')
          deleteStore('sdn_access_token')
          deleteStore('loginType')
          removeSessionStorage('walletAddress')
        }
      })
    } else {
      if (window?.mobileProvider) {
        window?.mobileProvider.on('accountsChanged', (accounts: any) => {
          console.log('meta mask change account：', accounts)
          if (accounts && accounts[0]) {
            window.loginType = 'mobile wallet'
            setStore('loginType', 'mobile wallet')
            globalStore.setState({
              // isLogin: true,
              provider: window?.mobileProvider,
              userAddress: accounts[0],
            })
            setSessionStorage('walletAddress', accounts[0])
            setAddress(accounts[0])
          } else {
            globalStore.setState({
              isLogin: false,
              userAddress: '',
            })
            deleteStore('sdn_user_address')
            deleteStore('sdn_user_id')
            deleteStore('sdn_access_token')
            deleteStore('loginType')
            removeSessionStorage('walletAddress')
          }
        })
      }
    }
  }

  const initSendingNetWork = () => {
    console.log('--------------sending NetWork init success-------------')
    const user_id = window.localStorage.getItem('sdn_user_id')
    const access_token = window.localStorage.getItem('sdn_access_token')
    if (user_id && access_token) {
      const res = sdk.createClient({
        baseUrl: sendingBaseUrl,
        userId: user_id,
        accessToken: access_token,
      })
      window.client = res
      globalStore.setState({
        client: res,
      })
      res?.startClient({})
    } else {
      const res = sdk.createClient({
        baseUrl: sendingBaseUrl,
        accessToken: 'null',
      })
      window.client = res
      globalStore.setState({
        client: res,
      })
      res?.startClient({})
    }
  }

  return (
    <>
      <Head>
        <title>AfterHours</title>
        <meta charSet="utf-8" />
        <meta name="App-Config" content="fullscreen=yes,useHistoryState=yes,transition=yes" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="yes" name="apple-touch-fullscreen" />
        <meta content="telephone=no,email=no" name="format-detection" />

        <meta name="application-name" content="AfterHours" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="AfterHours" />
        <meta name="description" content="AfterHours Description" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#2B5797" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#000000" />

        <link rel="apple-touch-icon" href="/icons/afterhours-logo.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/afterhours-logo.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/afterhours-logo.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/icons/afterhours-logo.png" />

        <link rel="icon" type="image/png" sizes="32x32" href="/icons/afterhours-logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/afterhours-logo.png" />
        <link rel="manifest" href="/manifest.json" />
        {/* <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" /> */}

        {/* <meta property="og:type" content="website" />
        <meta property="og:title" content="PWA App" />
        <meta property="og:description" content="Best PWA App in the world" />
        <meta property="og:site_name" content="PWA App" />
        <meta property="og:url" content="https://yourdomain.com" />
        <meta property="og:image" content="https://yourdomain.com/icons/apple-touch-icon.png" /> */}

        <meta
          key="viewport"
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
        />
        {/* <link rel="icon" href="./favicon.ico" type="image/x-icon" /> */}
        <link rel="apple-touch-icon" href="./favicon.ico" />
        <script src="https://static.particle.network/sdks/web/auth/0.14.2/auth.min.js"></script>
        <script src="https://static.particle.network/sdks/web/provider/0.14.2/provider.min.js"></script>
        {/* <script src="https://maps.googleapis.com/maps/api/js?key=661001edfd534997&callback=initMap"></script> */}
      </Head>
      <ChakraProvider resetCSS theme={theme}>
        <Layout>
          <Component {...(pageProps ?? {})} />
          <ToastContainer />
        </Layout>
      </ChakraProvider>
    </>
  )
}

// 这里要注意，切换语言会导致整体 APP 组件卸载再初始化
export default App
