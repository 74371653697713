import { Flex, FlexProps, Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import pv from '@/utils/px2vw'
import Footer from '../Footer'
import Header from '../Header'
import { useRouter } from 'next/router'
import globalStore from '@/stores/global'

function Index({ children }: FlexProps) {
  const router = useRouter()
  const { inputFocus } = globalStore()
  const pathName = router.pathname
  const [hideFooter, setHideFooter] = useState(true)

  useEffect(() => {
    if (!window) return
    if (window.ethereum) {
      setHideFooter(false)
    } else {
      setHideFooter(inputFocus)
    }
  }, [inputFocus])

  return (
    <Flex
      p={
        pathName === '/wallet'
          ? `0 0 ${pv(78)}`
          : pathName === '/share'
          ? `${pv(32)} ${pv(6)} ${pv(78)}`
          : `0 ${pv(16)} ${pv(94)}`
      }
      w="full"
      minH="100vh"
      flexDir="column"
      bgColor="black.200"
      pos="relative"
      overflow="hidden"
    >
      {pathName !== '/' &&
        pathName !== '/wallet' &&
        pathName !== '/ticketSecured' &&
        pathName !== '/chat' &&
        pathName !== '/share' && (
          <Header
            title={`${pathName.substring(1, 2).toUpperCase()}${pathName.substring(
              2,
              pathName.length
            )}`}
          />
        )}

      <Box pt={pathName !== '/wallet' && pathName !== '/channels' ? pv(72) : 0} w="100%">
        {children}
      </Box>
      {!hideFooter && <Footer />}
    </Flex>
  )
}

export default React.memo(Index)
