import { Center, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import pv from '@/utils/px2vw'
import explore_default from '@/assets/imgs/layout/explore_default.png'
import explore_active from '@/assets/imgs/layout/explore_active.png'
import wallet_default from '@/assets/imgs/layout/wallet_default.png'
import wallet_active from '@/assets/imgs/layout/wallet_active.png'
import channels_default from '@/assets/imgs/layout/channels_default.png'
import channels_active from '@/assets/imgs/layout/channels_active.png'
import { useRouter } from 'next/router'

function Index() {
  const router = useRouter()
  const [activePage, setActivePage] = useState(1)
  const bg = 'linear-gradient(0deg, rgba(54, 255, 192, 0.08), rgba(54, 255, 192, 0.08)), #071612'

  useEffect(() => {
    const pathName = router.pathname
    if (
      pathName === '/channels' ||
      pathName === '/chat' ||
      pathName === '/channelsDetail' ||
      pathName === '/channelsMore'
    ) {
      setActivePage(3)
    } else if (
      pathName === '/wallet' ||
      pathName === '/yourTicket' ||
      pathName === '/share' ||
      pathName === '/profile'
    ) {
      setActivePage(2)
    } else {
      setActivePage(1)
    }
  }, [router.pathname])

  return (
    <Flex
      p={`0 ${pv(16)}`}
      h={pv(78)}
      w="full"
      pos="fixed"
      bottom="0"
      left="0"
      boxSizing="border-box"
      bgColor="green.200"
      zIndex="9"
    >
      <SimpleGrid w="full" columns={3}>
        {/* Explore */}
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          onClick={() => router.push('/')}
        >
          <Center
            w={pv(64)}
            h={pv(32)}
            borderRadius={pv(99)}
            mb={pv(2)}
            bgGradient={activePage === 1 ? bg : ''}
          >
            <Image
              w={pv(24)}
              h={pv(24)}
              src={activePage === 1 ? explore_active : explore_default}
              alt="Explore"
            />
          </Center>
          <Text
            color={activePage === 1 ? 'green.100' : 'white.50'}
            textStyle="12"
            lineHeight={pv(16)}
          >
            Explore
          </Text>
        </Flex>
        {/* Channels */}
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          onClick={() => router.push('/channels')}
        >
          <Center
            w={pv(64)}
            h={pv(32)}
            borderRadius={pv(99)}
            mb={pv(2)}
            bgGradient={activePage === 3 ? bg : ''}
          >
            <Image
              w={pv(20)}
              h={pv(20)}
              src={activePage === 3 ? channels_active : channels_default}
              alt="Explore"
            />
          </Center>
          <Text
            color={activePage === 3 ? 'green.100' : 'white.50'}
            textStyle="12"
            lineHeight={pv(16)}
          >
            Channels
          </Text>
        </Flex>
        {/* My */}
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          onClick={() => router.push('/wallet')}
        >
          <Center
            w={pv(64)}
            h={pv(32)}
            borderRadius={pv(99)}
            mb={pv(2)}
            bgGradient={activePage === 2 ? bg : ''}
          >
            <Image
              w={pv(24)}
              h={pv(24)}
              src={activePage === 2 ? wallet_active : wallet_default}
              alt="Explore"
            />
          </Center>
          <Text
            color={activePage === 2 ? 'green.100' : 'white.50'}
            textStyle="12"
            lineHeight={pv(16)}
          >
            My
          </Text>
        </Flex>
      </SimpleGrid>
    </Flex>
  )
}

export default React.memo(Index)
