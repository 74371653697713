import create from 'zustand'

type State = {
  /* 随机数，用于刷新数据 */
  globalRandom: number
  /* provider */
  provider: any
  /* 是否登陆 */
  isLogin: boolean
  /* 钱包地址 */
  userAddress: string
  /* native coin价格 */
  nativeCoinPrice: string
  /* 币种价格 */
  tokenPrice: string
  /* 用户信息 */
  userInfo: any
  /* sending network */
  client: any
  /* sending input focus */
  inputFocus: boolean
  /* 链错误 */
  errorChain: boolean
}

const globalStore = create<State>(() => ({
  globalRandom: 0,
  provider: null,
  isLogin: false,
  userAddress: '',
  nativeCoinPrice: '',
  tokenPrice: '',
  userInfo: {},
  client: null,
  inputFocus: false,
  errorChain: false,
}))

export default globalStore
