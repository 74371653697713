import Ajax from '@/utils/request'

const ajax = new Ajax()

// 登陆
export const login = {
  fetcher: (params: string) => ajax.get(`/user/wallet/${params}`),
  key: '/user/wallet',
}

// 获取上传图片相关URL
export const getUploadUrls = {
  fetcher: (params: Record<string, string>) =>
    ajax.get(`/user/uploadUrl/${params?.userId}/${params?.fileName}`),
  key: '/user/uploadUrl',
}

// 检测用户名是否重复
export const getRepeatability = {
  fetcher: (params: string) => ajax.get(`/user/userName/${params}`),
  key: '/user/userName',
}

// 修改用户资料
export const upDateUserInfo = {
  fetcher: (params: Record<string, string>) => ajax.put(`/user`, params),
  key: '/user',
}

// 根据userId获取ticket列表
export const getTicketsByUserId = {
  fetcher: (params: string) => ajax.get(`/ticket/user/${params}`),
  key: '/ticket/user',
}

// 根据userId获取listings列表
export const getListingsByUserId = {
  fetcher: (params: string) => ajax.get(`/order/listing/offerer/${params}`),
  key: '/order/listing/offerer',
}

// 根据userId获取offers列表
export const getOffersByUserId = {
  fetcher: (params: string) => ajax.get(`/order/offer/offerer/${params}`),
  key: '/order/offer/offerer',
}

// 根据userId获取history列表
export const getHistoryByUserId = {
  fetcher: (params: string) => ajax.get(`/fulfillment/user/${params}`),
  key: '/fulfillment/user',
}

// 获取邮箱验证码
export const getEmailCode = {
  fetcher: (params: string) => ajax.get(`/user/verification/getCode/${params}`),
  key: '/user/verification/getCode',
}

// 验证邮箱
export const emailVerify = {
  fetcher: (params: Record<string, any>) => ajax.post(`/user/verification`, params),
  key: '/user/verification/verifyCode',
}
