import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'
import pv from '@/utils/px2vw'

function Index({ children, ...props }: ButtonProps) {
  return (
    <Button
      h={pv(40)}
      borderRadius={pv(99)}
      w="full"
      textStyle="16"
      color="white.100"
      bgColor="transparent"
      border="1px solid"
      borderColor="white.60"
      _active={{ bg: props?.bg, borderRadius: props?.borderRadius }}
      _hover={{ bg: props?.bg, borderRadius: props?.borderRadius }}
      _focus={{ bg: props?.bg, borderRadius: props?.borderRadius }}
      _focusVisible={{ bg: props?.bg, borderRadius: props?.borderRadius }}
      _visited={{ bg: props?.bg, borderRadius: props?.borderRadius }}
      _focusWithin={{ bg: props?.bg, borderRadius: props?.borderRadius }}
      _target={{ bg: props?.bg, borderRadius: props?.borderRadius }}
      _grabbed={{ bg: props?.bg, borderRadius: props?.borderRadius }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default React.memo(Index)
