import { Image, Center, CenterProps, ImageProps } from '@chakra-ui/react'
import React from 'react'
import pv from '@/utils/px2vw'
import theme from '@/theme'
import backIcon from '@/assets/imgs/icons/backIcon.png'
import heartIcon from '@/assets/imgs/icons/heartIcon.png'
import shareIcon from '@/assets/imgs/icons/shareIcon.png'
import locationIcon from '@/assets/imgs/icons/locationIcon.png'
import omitIcon from '@/assets/imgs/icons/omitIcon.png'
import searchIcon from '@/assets/imgs/icons/searchIcon.png'
import setUpIcon from '@/assets/imgs/icons/setUpIcon.png'
import rightIcon from '@/assets/imgs/icons/rightIcon.png'
import hammerIcon from '@/assets/imgs/icons/hammerIcon.png'
import nftIcon from '@/assets/imgs/icons/nftIcon.png'
import peoplesIcon from '@/assets/imgs/icons/peoplesIcon.png'
import infoIcon from '@/assets/imgs/icons/infoIcon.png'
import telegramIcon from '@/assets/imgs/icons/telegramIcon.png'
import earthIcon from '@/assets/imgs/icons/earthIcon.png'
import qrCodeIcon from '@/assets/imgs/icons/qrCodeIcon.png'
import qrCodeMiniIcon from '@/assets/imgs/icons/qrCodeMiniIcon.png'
import addIcon from '@/assets/imgs/icons/addIcon.png'
import addBlackIcon from '@/assets/imgs/icons/addBlackIcon.png'
import reductionIcon from '@/assets/imgs/icons/reductionIcon.png'
import radarIcon from '@/assets/imgs/icons/radarIcon.png'
import editIcon from '@/assets/imgs/icons/editIcon.png'
import editIconWhite from '@/assets/imgs/icons/editIconWhite.png'
import moneyIcon from '@/assets/imgs/icons/moneyIcon.png'

export const MoneyIcon = ({ ...props }: CenterProps) => {
  return (
    <Center
      borderRadius="50%"
      bgGradient={theme.colors.gradientGreen}
      w={pv(60)}
      h={pv(60)}
      {...props}
    >
      <Image alt="back" w={pv(32)} h={pv(32)} src={moneyIcon} />
    </Center>
  )
}

export const EditIcon = ({ ...props }: CenterProps) => {
  return (
    <Center
      borderRadius="50%"
      bgGradient={theme.colors.gradientGreen}
      w={pv(60)}
      h={pv(60)}
      {...props}
    >
      <Image alt="back" w={pv(32)} h={pv(32)} src={editIcon} />
    </Center>
  )
}

export const EditIconWhite = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="black.400" w={pv(32)} h={pv(32)} {...props}>
      <Image alt="back" w={pv(16)} h={pv(16)} src={editIconWhite} />
    </Center>
  )
}

export const RadarIcon = ({ ...props }: CenterProps) => {
  return (
    <Center
      borderRadius="50%"
      bgGradient={theme.colors.gradientGreen}
      w={pv(60)}
      h={pv(60)}
      {...props}
    >
      <Image alt="back" w={pv(36)} h={pv(32)} src={radarIcon} />
    </Center>
  )
}

export const ReductionIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="black.300" w={pv(24)} h={pv(24)} {...props}>
      <Image alt="back" w={pv(12)} h={pv(2)} src={reductionIcon} />
    </Center>
  )
}

export const AddIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="black.300" w={pv(24)} h={pv(24)} {...props}>
      <Image alt="back" w={pv(12)} h={pv(12)} src={addIcon} />
    </Center>
  )
}

export const AddBlackIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="green.100" w={pv(24)} h={pv(24)} {...props}>
      <Image alt="back" w={pv(12)} h={pv(12)} src={addBlackIcon} />
    </Center>
  )
}

export const QrCodeIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="green.100" w={pv(44)} h={pv(44)} {...props}>
      <Image alt="back" w={pv(20)} h={pv(20)} src={qrCodeIcon} />
    </Center>
  )
}

export const QrCodeMiniIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="black.400" w={pv(32)} h={pv(32)} {...props}>
      <Image alt="back" w={pv(14)} h={pv(14)} src={qrCodeMiniIcon} />
    </Center>
  )
}

export const EarthIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="black.400" w={pv(32)} h={pv(32)} {...props}>
      <Image alt="back" w={pv(16)} h={pv(16)} src={earthIcon} />
    </Center>
  )
}

export const TelegramIconIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="black.400" w={pv(32)} h={pv(32)} {...props}>
      <Image alt="back" w={pv(16)} h={pv(16)} src={telegramIcon} />
    </Center>
  )
}

export const InfoIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="white.30" w={pv(32)} h={pv(32)} {...props}>
      <Image alt="back" w={pv(16)} h={pv(16)} src={infoIcon} />
    </Center>
  )
}

export const PeoplesIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="white.30" w={pv(32)} h={pv(32)} {...props}>
      <Image alt="back" w={pv(20)} h={pv(16)} src={peoplesIcon} />
    </Center>
  )
}

export const RightIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="white.30" w={pv(32)} h={pv(32)} {...props}>
      <Image alt="back" w={pv(8)} h={pv(14)} src={rightIcon} />
    </Center>
  )
}

export const HammerIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgColor="blue.100" w={pv(32)} h={pv(32)} {...props}>
      <Image alt="back" w={pv(16)} h={pv(16)} src={hammerIcon} />
    </Center>
  )
}

interface NFTIconImage extends CenterProps {
  imgProps?: ImageProps
}

export const NFTIcon = ({ imgProps, ...props }: NFTIconImage) => {
  return (
    <Center borderRadius="50%" bgColor="blue.100" w={pv(44)} h={pv(44)} {...props}>
      <Image alt="back" w={pv(24)} h={pv(24)} src={nftIcon} {...imgProps} />
    </Center>
  )
}

export const SetUpIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgGradient={theme.colors.gradient} w={pv(40)} h={pv(40)} {...props}>
      <Image alt="back" w={pv(16)} h={pv(15)} src={setUpIcon} />
    </Center>
  )
}

export const SearchIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgGradient={theme.colors.gradient} w={pv(40)} h={pv(40)} {...props}>
      <Image alt="back" w={pv(24)} h={pv(24)} src={searchIcon} />
    </Center>
  )
}

export const OmitIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgGradient={theme.colors.gradient} w={pv(40)} h={pv(40)} {...props}>
      <Image alt="back" w={pv(13)} h={pv(3)} src={omitIcon} />
    </Center>
  )
}

export const LocationIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgGradient={theme.colors.gradient} w={pv(40)} h={pv(40)} {...props}>
      <Image alt="back" w={pv(12)} h={pv(17)} src={locationIcon} />
    </Center>
  )
}

export const ShareIcon = ({ ...props }: CenterProps) => {
  return (
    <Center borderRadius="50%" bgGradient={theme.colors.gradient} w={pv(40)} h={pv(40)} {...props}>
      <Image alt="back" w={pv(16)} h={pv(16)} src={shareIcon} />
    </Center>
  )
}

interface HearIconProps extends CenterProps {
  imgProps?: ImageProps
}

export const HeartIcon = ({ imgProps, ...props }: HearIconProps) => {
  return (
    <Center borderRadius="50%" bgGradient={theme.colors.gradient} w={pv(40)} h={pv(40)} {...props}>
      <Image alt="back" w={pv(16)} h={pv(14)} src={heartIcon} {...imgProps} />
    </Center>
  )
}

function BackIcon({ ...props }: CenterProps) {
  return (
    <Center borderRadius="50%" bgGradient={theme.colors.gradient} w={pv(40)} h={pv(40)} {...props}>
      <Image alt="back" w={pv(24)} h={pv(24)} src={backIcon} />
    </Center>
  )
}

export default BackIcon
