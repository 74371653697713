import { Seaport } from '@opensea/seaport-js'
import { Contract, ethers } from 'ethers'
import erc1155 from './abis/erc1155.json'
import erc721 from './abis/erc721.json'
import erc20 from './abis/erc20.json'
import { getStore } from '@/utils/storage'
import globalStore from '@/stores/global'

export const sendingBaseUrl = 'https://portal0101.sending.network'

export const DefaultChain = {
  name: process.env.NODE_ENV === 'development' ? 'Matic Testnet Mumbai' : 'Matic Testnet Mumbai',
  chainId: process.env.NODE_ENV === 'development' ? 80001 : 80001,
  rpc:
    process.env.NODE_ENV === 'development'
      ? 'https://polygon-mumbai.infura.io/v3/99098d6613a846b38ceb40ef23078570'
      : 'https://polygon-mumbai.infura.io/v3/99098d6613a846b38ceb40ef23078570',
  scan:
    process.env.NODE_ENV === 'development'
      ? 'https://mumbai.polygonscan.com/'
      : 'https://mumbai.polygonscan.com/',
  nativeCoin:
    process.env.NODE_ENV === 'development'
      ? {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        }
      : {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
}

export const DefaultToken = {
  name: process.env.NODE_ENV === 'development' ? 'WETH' : 'WETH',
  address:
    process.env.NODE_ENV === 'development'
      ? '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa'
      : '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
}

export enum ItemType {
  NATIVE = 0,
  ERC20 = 1,
  ERC721 = 2,
  ERC1155 = 3,
  ERC721_WITH_CRITERIA = 4,
  ERC1155_WITH_CRITERIA = 5,
}

/**
 * 初始化sdk合约
 * @param address 合约地址
 * @param abi 合约abi
 */
const getReadContract = (address: string, abi: any[], provider: any) => {
  return new Contract(address, abi, provider)
}

/**
 * 初始化wallet合约
 * @param address 合约地址
 * @param abi 合约abi
 */
const getWalletContract = (address: string, abi: any[], signer?: any) => {
  return new Contract(address, abi, signer)
}

export { getReadContract, getWalletContract }

/**
 *
 * @param hashCallback hash返回的callback
 * @param completeCallback 交易完成的callback
 * @param value 手续费
 */

export async function sendTransactionFun(
  { contract, method, value = 0, args = [] }: any,
  hashCallback: any,
  completeCallback: any
) {
  const userAddress = (await window.web3.eth.getAccounts())[0]
  try {
    const gasUsed = await contract.estimateGas[method](...args, { value, from: userAddress })
    const gasLimit = gasUsed.mul(130).div(100)
    // console.log(gasUsed, gasLimit)
    const ops = { value, gasLimit, from: userAddress }
    const tx = await contract[method](...args, ops)
    hashCallback && hashCallback(tx.hash)
    // 拿到交易hash
    tx.wait().then((res: any) => {
      // 交易完成
      completeCallback && completeCallback(res)
    })
  } catch (err) {
    // 错误查询错误信息
    try {
      await contract.connect(userAddress).callStatic[method](...args, { value })
      completeCallback && completeCallback({ err })
    } catch (error) {
      completeCallback && completeCallback({ err: error })
    }
  }
}

/**
 * portal 错误信息翻译功能
 * @param e 错误信息
 * @returns 提示文字
 */
export const portalErrorTranslation = (e: any) => {
  const res = e?.reason || e?.message || e
  return res
}

/**
 * portal 错误信息翻译功能
 * @param e 错误信息
 * @returns 提示文字
 */
export const connectWallet = async (id: string, options?: any) => {
  try {
    await window.connectKit.connect(id, options)
  } catch (error) {
    console.error('connectWallet', error)
  }
}

// 发起交易
export const makeTransaction = async (
  orderObj: any,
  userAddress: string,
  errorCallBack?: (err: string) => void
) => {
  console.log('创建订单')
  try {
    // const provider = new ethers.providers.Web3Provider(
    //   window.loginType === 'particle' ? window.provider : window.ethereum
    // )
    const provider =
      getStore('loginType') === 'particle'
        ? window.particleProvider || JSON.parse(getStore('particleProvider'))
        : new ethers.providers.Web3Provider(window.ethereum)
    const seaport = new Seaport(provider, {
      seaportVersion: '1.5',
    })
    const { executeAllActions } = await seaport.createOrder(orderObj, userAddress)
    const order = await executeAllActions()
    console.log(order, 'result')
    return order
  } catch (err) {
    const res = portalErrorTranslation(err)
    errorCallBack?.(String(res))
  }
}

// 接受交易
export const makeFulfill = async (order: any, errorCallBack?: (err: string) => void) => {
  try {
    const provider =
      getStore('loginType') === 'particle'
        ? window.particleProvider || JSON.parse(getStore('particleProvider'))
        : new ethers.providers.Web3Provider(window.ethereum)
    const seaport = new Seaport(provider, {
      seaportVersion: '1.5',
    })
    console.log(order, 'order')
    const { executeAllActions: executeAllFulfillActions } = await seaport.fulfillOrder(order)
    const transaction = (await executeAllFulfillActions()).wait()
    return transaction
  } catch (err) {
    console.log(err, '错误！！！')
    const res = portalErrorTranslation(err)
    errorCallBack?.(String(res))
  }
}

// 查询erc1155余额数量
export const getNFTBalance = async (
  address: string,
  id: string,
  userAddress: string,
  is721?: boolean
) => {
  try {
    const provider = new ethers.providers.Web3Provider(
      window.loginType === 'particle' ? window.provider : window.ethereum
    )
    // ERC1155
    if (!is721) {
      const contract = getReadContract(address, erc1155, provider)
      return await contract?.balanceOf(userAddress, id)
    } else {
      const contract = getReadContract(address, erc721, provider)
      return await contract?.balanceOf(userAddress)
    }
  } catch (err) {
    console.log(err)
  }
}

// 检查网络
export const checkChainId = async () => {
  if (getStore('loginType') !== 'particle') {
    const metaMaskChainId = window?.ethereum?.chainId / 1
    if (metaMaskChainId !== DefaultChain.chainId) {
      globalStore.setState({
        errorChain: true,
      })
      return true
    } else {
      globalStore.setState({
        errorChain: false,
      })
      return false
    }
  }
}

// 检查网络
export const switchOrAddChain = async () => {
  try {
    await window?.ethereum?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${DefaultChain.chainId.toString(16)}` }],
    })
    return true
  } catch (switchError: any) {
    console.log(switchError?.code, 'code')
    if (switchError?.code === 4902) {
      // 不存在该网络
      try {
        await window?.ethereum?.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${DefaultChain.chainId.toString(16)}`,
              chainName: DefaultChain.name,
              rpcUrls: DefaultChain.rpc,
              blockExplorerUrls: DefaultChain.scan,
              nativeCurrency: DefaultChain.nativeCoin,
            },
          ],
        })
        return true
      } catch (addError: any) {
        console.log('addEthereumChain Error:' + addError?.message)
        return false
      }
    } else {
      return false
    }
  }
}

/**
   获取用户当前Token余额，目前包含Native Coin和Default Token(合约获取)
   @param userAddress 用户钱包地址
   @returns 余额
*/
export const getTokenBalance = async (userAddress: string) => {
  try {
    let provider = null
    if (getStore('loginType') === 'particle') {
      provider = window.particleProvider
    } else {
      provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
    }
    const nativeCoinBalance = await provider.getBalance(userAddress)
    const ethersSigner = provider.getSigner()
    const contract = getWalletContract(DefaultToken.address, erc20, ethersSigner)
    const tokenBalance = await contract.balanceOf(userAddress)
    return {
      nativeCoin: nativeCoinBalance.toString(),
      defaultToken: tokenBalance.toString(),
    }
  } catch (err) {
    console.log(err, 'err')
  }
}
