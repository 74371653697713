import { Text, Flex, FlexProps, Image } from '@chakra-ui/react'
import React from 'react'
import pv from '@/utils/px2vw'
import BackIcon from '../BaseIcon'
import { useRouter } from 'next/router'
import headerShare from '@/assets/imgs/event/headerShare.png'

interface IProp extends FlexProps {
  title: string
}

function Index({ title }: IProp) {
  const router = useRouter()
  const returnTitle = () => {
    switch (title) {
      case 'ListingSuccess':
        return 'Success'
      case 'SellTickets':
        return 'Sell Tickets'
      case 'MakeOffer':
        return 'Make an Offer'
      case 'VerifyEmail':
        return 'Email Verification'
      case 'YourTicket':
        return 'Your Ticket'
      case 'ChannelsMore':
        return 'Community'
      case 'ChannelsDetail':
        return 'Channel'
      default:
        return title
    }
  }
  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent="center"
      pos="fixed"
      width="100%"
      bgColor="black.200"
      left={0}
      p={`${pv(16)} 0 ${pv(16)} 0 `}
      zIndex={99}
    >
      {router.pathname !== '/channels' && (
        <BackIcon pos="absolute" ml={pv(16)} left="0" onClick={() => router.back()} />
      )}
      <Text h={pv(40)} lineHeight={pv(40)} textStyle="16" fontWeight="700">
        {returnTitle()}
      </Text>
      {router.pathname === '/yourTicket' && (
        <Image
          alt="share"
          pos="absolute"
          right="0"
          src={headerShare}
          w={pv(40)}
          h={pv(40)}
          onClick={() => router.push('/channels')}
        />
      )}
    </Flex>
  )
}

export default React.memo(Index)
