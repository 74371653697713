import { Flex, Text, FlexProps } from '@chakra-ui/react'
import React from 'react'
import pv from '@/utils/px2vw'
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons'

interface IProps extends FlexProps {
  text?: string
  icon?: React.ReactNode
  textRender?: React.ReactNode
  closeClick?: () => void
}

function Index({ text, icon, textRender, closeClick, ...props }: IProps) {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      bgColor="green.100"
      w="full"
      h="fit-content"
      py={pv(10)}
      px={pv(16)}
      borderRadius={pv(4)}
      mb={pv(32)}
      {...props}
    >
      {icon || <CheckCircleIcon color="black.100" fontSize={pv(20)} mr={pv(8)} />}
      {textRender || (
        <Text color="black.100" textStyle="16" fontWeight="600" lineHeight={pv(20)}>
          {text}
        </Text>
      )}
      <CloseIcon color="red.300" fontWeight="600" onClick={() => closeClick?.()} />
    </Flex>
  )
}

export default React.memo(Index)
